/*
 * Bones Scripts File
 * Author: Eddie Machado & Todor Karastoyanov

 * Get Viewport Dimensions
 * returns object with viewport dimensions to match css in width and height properties
 * ( source: http://andylangton.co.uk/blog/development/get-viewport-size-width-and-height-javascript )
*/



function updateViewportDimensions() {
	var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
	return { width:x,height:y };
}
// setting the viewport width
var viewport = updateViewportDimensions();
/*
 * Throttle Resize-triggered Events
 * Wrap your actions in this function to throttle the frequency of firing them off, for better performance, esp. on mobile.
 * ( source: http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed )
*/
var waitForFinalEvent = (function () {
	var timers = {};
	return function (callback, ms, uniqueId) {
		if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
		if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
		timers[uniqueId] = setTimeout(callback, ms);
	};
})();
// how long to wait before deciding the resize has stopped, in ms. Around 50-100 should work ok.
var timeToWaitForLast = 100;


const menuOperations = (e, d, j) => {
	e.addEventListener('click', (f)=>{
		var ft = f.target
		if(ft == e) {
			if(ft.classList.contains(d)) {
				document.body.classList.remove(j);
				ft.classList.remove(d)
			}
			else {
				document.body.classList.toggle(j);
				ft.classList.toggle(d)
			}
		}
	})
}
menuOperations(document.querySelector('.header-nav'), 'open-nav', 'open-nav-body')

/* https://stackoverflow.com/questions/23885255/how-to-remove-ignore-hover-css-style-on-touch-devices */
function watchForHover(container) {
    var hasHoverClass = false,
        lastTouchTime = 0;
    function enableHover() {
        // filter emulated events coming from touch events
        if (new Date() - lastTouchTime < 500) return;
        if (hasHoverClass) return;
        container.className += ' has-hover';
        hasHoverClass = true;
    }
    function disableHover() {
        if (!hasHoverClass) return;
        container.className = container.className.replace(' has-hover', ' has-no-hover');
        hasHoverClass = false;
    }
    function updateLastTouchTime() {
        lastTouchTime = new Date();
    }
    document.addEventListener('touchstart', updateLastTouchTime, true);
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);
    enableHover();
}
watchForHover(document.querySelector('body'));

//create search button
const openSearch = (e) => {
	if(e) {
		let f = document.createElement("button"),
			m = document.createElement("span")
		f.setAttribute("type", "button")
		f.setAttribute("class", "menu-item-button")
		f.appendChild(m)
		m.innerHTML = "Start search"
		e.appendChild(f);
	}
}
openSearch(document.querySelector('.menu-item-104'))

const toggleContainers = (a, b) => {
	window.addEventListener("click", (e)=>{
		var etarget = e.target
		if(etarget.name && etarget.name == "_sf_submit") {
			b.classList.remove('expand')
			document.querySelector('.header-nav').classList.remove('open-nav')
		}
		if(!etarget.classList.contains("menu-item-button") && !etarget.classList.contains("sf-input-text") && !etarget.classList.contains("sf-input-select")) {
			b.classList.remove('expand')
			return
		}
		else b.classList.add('expand')
	})
}
toggleContainers(document.querySelector('.menu-item-104'), document.body )

const findSlider = (d) => {
	if(d) {
		let child1 = d.children[0],
			child2 = d.children[0].children[0],
			child3 = child2.children,
			child3l = child3.length,
			i,
			arrowLeft = document.createElement("div"),
			arrowRight = document.createElement("div"),
			arrowContainer = document.createElement("div")
			
		arrowRight.setAttribute("class", "swiper-button-next-program")
		arrowLeft.setAttribute("class", "swiper-button-prev-program")

		arrowContainer.appendChild(arrowLeft)
		arrowContainer.appendChild(arrowRight)
		child1.parentNode.parentNode.appendChild(arrowContainer)
		child2.classList.add('swiper-wrapper')
		arrowContainer.classList.add('swiper-container-program-nav-wrapper')
	}
}
findSlider(document.querySelector('.swiper-container-program'))


const scrollToTop = () => {
	const c = document.documentElement.scrollTop || document.body.scrollTop;
	if (c > 0) {
		window.requestAnimationFrame(scrollToTop);
		window.scrollTo(0, c - c / 12);
	}
}
document.querySelector('.back-to-top').addEventListener('click', function(){
	scrollToTop();
})

const scrollFromTop = (d) => {
	const c = document.documentElement.scrollTop || document.body.scrollTop;
	(c > 300) ? d.classList.add('show') : d.classList.remove('show');
}

window.addEventListener('scroll', function(){
	scrollFromTop(document.querySelector('.back-to-top'));
})

//function dateDisplay() {
//    var d = new Date();
//    var n = d.getFullYear();
//    var dateDisplay = document.getElementById("dateDisplay");
//    dateDisplay.innerHTML = n;
//}
//dateDisplay()

const accordion = (e) => {
	e.forEach((f)=>{
		let nexte = f.nextElementSibling
		f.addEventListener('click', (h)=>{
				let ht = h.target
			ht.classList.toggle('toggler')
			ht.nextElementSibling.classList.toggle('toggled')
		})
	})
}
accordion(document.querySelectorAll('.accordion-title'))

const getCal = (n, g) => {
	n.forEach((i) => {
	    window.addEventListener('click', (f)=>{

	        let ft = f.target,
	            ftp = ft.parentNode.parentNode,
	            ftplh= ft.placeholder

	        if(ft.tagName == "LABEL") {
	            ftp = ft.parentNode
	            ftp.classList.add('targeted')
	        } else {
	            i.classList.remove('targeted')
	        }
	        if(ft.tagName == "INPUT" && ft.classList.contains('sf-input-date') ) {
	            ftp = ft.parentNode.parentNode
	            ftp.classList.add('targeted')
	            if(ftplh == "До дата") {
	                g.classList.add('shifted')
	            } else{
	                g.classList.remove('shifted')
	            }
	        } else {
	            i.classList.remove('targeted')
	        }
	    })
	});
}

/*
const imgResizing = (img, aa) => {
	if(aa != undefined) {
		aa.forEach( (s) => {
			img.forEach( (f) => {
				var fw = f.offsetWidth,
					fh = f.offsetHeight
				if(fw > fh) {
					f.style.width = s.offsetWidth + 'px'
					f.style.minHeight = "100%"
				}
				if(fw < fh) {
					f.style.width = s.offsetWidth + 'px'
					f.style.height = "auto"
				}
			})
		})
	}
}
imgResizing(document.querySelectorAll('.lcp-left img'), document.querySelectorAll('.lcp-left a'))
window.addEventListener('resize', function(){imgResizing(document.querySelectorAll('.lcp-left img'), document.querySelectorAll('.lcp-left a'))})
imgResizing(document.querySelectorAll('.month-posts img'))
window.addEventListener('DOMContentLoaded', function(){
	window.setTimeout(function(){
		imgResizing(document.querySelectorAll('.lcp-left img'), document.querySelectorAll('.lcp-left a'))
	}, 200)
})
*/





const checkBoxes = (g) => {
	g.forEach((h)=>{
		h.addEventListener('click', (j)=> {
			let jt = j.target
			if(jt.nodeName == 'INPUT') {
				(jt.checked) ? jt.classList.add('checked') : jt.classList.remove('checked')
			}
		})
	})
}

checkBoxes(document.querySelectorAll('.mc-field-group-ul input[type="checkbox"]'))



var inputFile = (inputF) => { 
	
	//console.log(inputF[0].value)
	
	inputF.forEach( (e, index) => {
		
		var resultCont = document.createElement('p'),
			ep = e.parentNode.parentNode
		
		resultCont.setAttribute('class', 'results-p')	
		
		ep.appendChild(resultCont)
				
		
		e.addEventListener('change', (w) => {
			var wtt = w.target,
				wtp = wtt.parentNode.parentNode 
			
			console.log(wtp)
			if(resultCont.innerHTML == '') resultCont.innerHTML = wtt.value.replace(/\\$/,'').split('\\').pop()
			else {
				resultCont.innerHTML = wtt.value.replace(/\\$/,'').split('\\').pop()
			}
			
			//ep.appendChild(resultContInn)
		
		})
		
	}) 
	
	
}


addEventListener("DOMContentLoaded", (event) => {

	const getSearchMenuItems = (e, f, z, u) => {
		e.forEach((g)=>{
			let targetConcrete = g.innerHTML.includes(f)
			z.forEach((h)=>{
                h.style.display = "none";
    			u.forEach((b)=>{
    				var targetCurrent = b.innerHTML.includes(f)
    				if(targetCurrent) {
    					h.style.display = "block";
    				}
    			})
    			g.addEventListener("click", ()=>{
                    targetConcrete ? h.style.display = "block" : h.style.display = "none"
    			})
            })
		})
	}

	const toggleElements = (e, f) => {
		if(e) {
			e.addEventListener('click', ()=>{
				f.classList.toggle('open-element')
				e.classList.toggle('opener')
			})
		}
	}
	toggleElements(document.querySelector('.application-form-title'), document.querySelector('.application-form-body'))

});


